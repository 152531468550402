<template>
  <div class="about">
    <nav-bar />
    <banner />
    <example />
    <aboutUs :showMore="true" />
    <container-title title="优势" desc="Advantages" />
    <div class="container">
      <div class="about-list">
        <div class="about-item" v-for="(item, index) in aboutList" :key="index">
          <div
            class="about-icon wow animate__slideInRight"
            :data-wow-duration="1 + index + 's'"
          >
            <img :src="item.img" alt="" />
            <p>{{ item.title }}</p>
          </div>
          <div
            class="about-desc wow animate__slideInRight"
            data-wow-duration="1s"
          >
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '../components/nav'
import Footer from '../components/Footer'
import banner from '../components/banner'
import example from '../components/example'
import AboutUs from '../components/AboutUs'
import containerTitle from '../components/containerTitle'
export default {
  name: 'Home',
  components: {
    NavBar,
    Footer,
    banner,
    example,
    AboutUs,
    containerTitle
  },
  data() {
    return {
      aboutList: [
        {
          img: require('../assets/about-1.png'),
          title: '成熟的项目管理模式',
          desc:
            'CMM3项目管理模式体系，对应项目全生命周期的管理需求;从项目策划到交付，精准实践每个核心步骤的管理理论;创造诸多大型IT项目成功案例，赢得广泛好评;'
        },
        {
          img: require('../assets/about-2.png'),
          title: '丰富的人力资源储备',
          desc:
            '员工技能涵盖JAVA、安卓、IOS、H5、GO语言等多个领域;团队具备丰富的项目管理经验，良好的人员结构与技术层次，能快速响应客户需求;'
        },
        {
          img: require('../assets/about-3.png'),
          title: '完善的培训体系',
          desc:
            '为新员工提供多种技能的定向培训和岗前培训;让员工成为真正拥有技术、外语以及熟悉规模化项目运作模式的高端复合型人才;'
        },
        {
          img: require('../assets/about-4.png'),
          title: '',
          desc:
            '2020年3月通过ISO9000质量管理体系认证；2020年9月取得高新技术企业认定；截至目前为止拥有软件著作权12项;'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.about-list {
  padding-top: 50px;
  padding-bottom: 100px;
  .about-item {
    margin-bottom: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .about-icon {
      margin-right: 100px;
      img {
        width: 240px;
        height: 150px;
      }
      p {
        margin-top: 10px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
      }
    }
    .about-desc {
      padding-bottom: 40px;
      text-align: left;
      color: rgb(153, 153, 153);
      font-size: 14px;
      line-height: 26px;
    }
  }
}
</style>
